import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  MicroCmsLayout,
  CArticlePagingList,
} from "../../components/_index";
import console from "console";

// markup
const SubPage = ({ data, pageContext }: any) => {
  const news = data.microcmsInfoHotelstop;
  return (
    <Layout isKv={false}>
      <SEO
        title={news.title}
        description={news.description}
        ogimage={news.eyecatch?.url}
      />
      <CBreadCrumb
        data={{
          parent: [{ label: "お知らせ", path: "/news/" }],
          current: {
            label: news.title,
          },
        }}
      />
      <MicroCmsLayout data={news}>
        <CArticlePagingList
          prev={
            pageContext.prev && {
              link: {
                href: `/news/${pageContext.prev}`,
              },
            }
          }
          index={{
            label: "お知らせ一覧",
            link: {
              href: "/news/",
            },
          }}
          next={
            pageContext.next && {
              link: {
                href: `/news/${pageContext.next}`,
              },
            }
          }
        />
      </MicroCmsLayout>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query NewsDetail($id: String!) {
    microcmsInfoHotelstop(id: { eq: $id }) {
      description
      eyecatch {
        url
        width
        height
      }
      important
      infoHotelstopId
      link {
        href
        blank
      }
      pdf {
        url
      }
      publishedAt
      title
      contents {
        fieldId
        heading_text
        wysiwyg
        box
        align
        caption
        label
        table
        youtube
        htmlcode
        type
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            align
            caption
            label
            table
            youtube
            htmlcode
            type
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`;
